import './App.css';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Home from './Components/Home';
import Collection from './Components/Collection/Collection';
import SignIn from './Components/Admin/SignIn';
import NewArtwork from './Components/Admin/NewArtwork.jsx';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import ProtectedRoute from './Components/ProtectedRoutes/ProtectedRoutes';
import { useSelector } from 'react-redux';
import EditRemoveArtwork from './Components/Admin/EditRemoveArtwork';
import SpecificArtwork from './Components/Collection/SpecificArtwork';

function App() {
	const reduxUser = useSelector((state) => state.auth.userEmail);
	return (
		<Router>
			<ScrollToTop />
			<Switch>
				<Route path='/' exact={true} component={Home} />
				<Route path='/collection' exact={true} component={Collection} />
				<Route path='/collection/specific-art/:id' component={SpecificArtwork} />
				<Route path='/dl' exact={true} component={SignIn} />
				<ProtectedRoute user={reduxUser === 'dlwell292@gmail.com' || reduxUser === 'williamwellman98@gmail.com'} path='/new-artwork' component={NewArtwork} />
				<ProtectedRoute user={reduxUser === 'dlwell292@gmail.com' || reduxUser === 'williamwellman98@gmail.com'} path='/edit-artwork' component={EditRemoveArtwork} />
			</Switch>
		</Router>
	);
}

export default App;
