import React from 'react';
import './NavBar.scss';
import logo from '../../Assets/dw_logo.jpeg';

const NavBar = ({ leftButton, rightButton, leftTitle = 'Refresh', rightTitle = 'Home' }) => {
	return (
		<div className='nav-container'>
			<div className='nav-left'>
				<img className='nav-logo' src={logo} alt={'logo'} />
			</div>
			<div className='nav-right'>
				<p onClick={rightButton}>{rightTitle}</p>
				<p onClick={leftButton}>{leftTitle}</p>
			</div>
		</div>
	);
};
export default NavBar;
