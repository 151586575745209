import React from 'react';
import './OptionBox.scss';
import Box1 from '../../Assets/dlBox1.jpeg';
import Box2 from '../../Assets/dlBox2.jpeg';
import Box3 from '../../Assets/dlBox3.jpeg';
import Logo from '../../Assets/dlLogoTwo.jpeg';
import EmailLogo from '../../Assets/dlEmailIcon.png';

const OptionBox = ({ buttonEmailOnClick, buttonTwoOnClick, buttonThreeOnClick }) => {
	return (
		<div className=' '>
			<div className='option-block-logo'>
				<img src={Logo} alt={'textBox'} className='option-img-logo' />
				<div className='home-title'>Douglas Wellman</div>
				<div className='home-subtitle'>Hand Drawn & Original</div>
			</div>
			<div className='option-parent'>
				<div className='option-block' onClick={buttonTwoOnClick}>
					<h4 className='option-text'>Collection</h4>
					<img src={Box2} alt={'textBox'} className='option-img' />
				</div>
				<div className='option-block' onClick={buttonThreeOnClick}>
					<h4 className='option-text' >Portal</h4>
					<img src={Box3} alt={'textBox'} className='option-img' />
				</div>
				<div>
					<a href='mailto:dlwell292@gmail.com?subject=Interested_via_website&cc=williamwellman98@gmail.com'>
						<div className='option-block-contact' onClick={buttonEmailOnClick}>
							<img src={EmailLogo} alt={'textBox'} className='option-img-contact-email-logo' />
							<img src={Box1} alt={'textBox'} className='option-img-contact' />
						</div>
					</a>
				</div>
			</div>
		</div>
	);
};
export default OptionBox;
