import React, { useState } from 'react';
import firebase from '../Firebase/FirebaseConfig';
import NavBar from '../NavBar/NavBar';
import { useHistory } from 'react-router-dom';
import './Admin.scss';
import { getCollection } from '../../redux/actions/authActions';
import { useDispatch } from 'react-redux';

const UploadNewWork = () => {
	//move to initial state of editProfile Reducer
	const history = useHistory();
	const dispatch = useDispatch();

	const [
		completed,
		setCompleted
	] = useState(false);

	const [
		pic,
		setPic
	] = useState({
		newImage     : '',
		progress     : 0,
		title        : 'Massy Mass',
		printCost    : '$30',
		originalCost : '$200',
		description  : 'None Given',
		materials    : 'None Given',
		category     : 'people'
	});

	//move to action creator
	const fileSelector = (e) => {
		console.log(e.target.files[0]);
		if (e.target.files[0]) {
			setPic({ ...pic, newImage: e.target.files[0], name: pic.title });
		}
	};

	//move to action creator
	const updatePic = async () => {
		if (pic.newImage) {
			//args file

			const storageRef = firebase.storage().ref().child(pic.title);
			const uploadImage = storageRef.put(pic.newImage);

			uploadImage.on('state_changed', () => {
				storageRef
					.getDownloadURL()
					.then((url) => {
						//reducer dispatch set_url

						let { title, printCost, originalCost, description, materials, category } = pic;

						firebase
							.firestore()
							.collection('collection')
							.doc(`${title}`)
							.set({
								url,
								title,
								printCost,
								originalCost,
								description,
								materials,
								category,
								timestamp    : firebase.firestore.FieldValue.serverTimestamp()
							})
							.then(() => {
								setCompleted(true);
							})
							.catch((err) => console.log('In createDoc error', err));
						//update users file
					})
					.catch((err) => console.log('in Storage Error', err));
			});
		}
	};
	if (completed) {
		setTimeout(() => {
			dispatch(getCollection());
			history.push('/');
		}, 1200);
	}

	const handleTitle = (e) => {
		setPic({ ...pic, title: e.target.value });
	};

	const handleOriginalCost = (e) => {
		let cost = `$${e.target.value}`;
		setPic({ ...pic, originalCost: cost });
	};
	const handlePrintCost = (e) => {
		let cost = `$${e.target.value}`;
		setPic({ ...pic, printCost: cost });
	};
	const handleDescription = (e) => {
		setPic({ ...pic, description: e.target.value });
	};
	const handleMaterials = (e) => {
		setPic({ ...pic, materials: e.target.value });
	};

	return (
		<div className='add-new-container' style={{ minHeight: 200 }}>
			<NavBar leftTitle={'Home'} leftButton={() => history.push('/')} rightTitle={'Back'} rightButton={() => history.push('/dl')} />
			<div className='input-option-block'>
				<div>
					<h2>Add New Work</h2>
					<h3 style={{ paddingTop: '40px' }}>Enter all fields</h3>
				</div>
				<div>
					<p>Image</p>
					<input id='changePic' type='file' onChange={fileSelector} />
				</div>
				<div>
					<p>Title</p>
					<input id='title' type='text' onChange={handleTitle} placeholder={'Artwork Title'} />
				</div>
				<div>
					<p>Category</p>

					<select onChange={(e) => setPic({ ...pic, category: e.target.value })}>
						<option value='people'>people</option>
						<option value='animals'>animals</option>
						<option value='interpretive'>interpretive</option>
						<option value='meaningful'>meaningful</option>
					</select>
				</div>
				<div>
					<p>Original Cost</p>
					<input id='originalCost' type='text' onChange={handleOriginalCost} placeholder={'Original Cost'} />
				</div>
				<div>
					<p>Print Cost</p>
					<input id='printCost' type='text' onChange={handlePrintCost} placeholder={'Print Cost'} />
				</div>
				<div>
					<p>Description</p>
					<input id='description' type='textarea' onChange={handleDescription} placeholder={'Description'} />
				</div>
				<div>
					<p>Materials</p>
					<input id='materials' type='text' onChange={handleMaterials} placeholder={'Materials'} />
				</div>
				<h3 style={{ paddingTop: '40px' }}>Review</h3>
				<div>
					{pic.title && (
						<div>
							{' '}
							<b>title: </b> {pic.title}
						</div>
					)}
				</div>
				<div>
					{pic.category && (
						<div>
							{' '}
							<b>category: </b> {pic.category}
						</div>
					)}
				</div>
				<div>
					{pic.originalCost && (
						<div>
							{' '}
							<b>originalCost: </b> {pic.originalCost}
						</div>
					)}
				</div>
				<div>
					{pic.printCost && (
						<div>
							{' '}
							<b>printCost: </b> {pic.printCost}
						</div>
					)}
				</div>
				<div>
					{pic.description && (
						<div>
							{' '}
							<b>Description: </b> {pic.description}
						</div>
					)}
				</div>

				<div>
					{pic.materials && (
						<div>
							{' '}
							<b>Materials: </b> {pic.materials}
						</div>
					)}
				</div>
				{<button onClick={updatePic}>Upload</button>}
			</div>
		</div>
	);
};
export default UploadNewWork;
