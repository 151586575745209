import React from 'react';

const ArtUnit = ({ title, description, printCost, url, materials, originalCost, onClick }) => {
	return (
		<div className='collection-block'>
			<div className='image-group' key={title} onClick={onClick}>
				<div className='image-group'>
					<div className='overlay-text'>Douglas Wellman</div>
					<img src={`${url}`} alt={`${title}`} className='collection-image' />
				</div>
				<div className='art-info-block'>
					<div className='collection-title'>{title}</div>

					<div className='collection-subtitle'>"{description ? description : null}"</div>

					<br />
					<div className='collection-subtitle'>
						<div className='art-price'>
							<p>Materials</p>
							<p>{materials}</p>
						</div>
					</div>
					<div className='collection-subtitle'>
						<div className='art-price'>
							<p>Original</p>
							<p>{originalCost}</p>
						</div>
					</div>
					<div className='collection-subtitle'>
						{' '}
						<div className='art-price'>
							<p>Print Cost</p>
							<p>{printCost}</p>
						</div>
					</div>
					<br />
				</div>
			</div>
		</div>
	);
};
export default ArtUnit;
