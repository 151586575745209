const initialState = {
	collection   : [],
	loading      : false,

	userName     : '',
	userEmail    : '',
	message      : '',
	title        : '',
	description  : '',
	materials    : '',
	originalCost : '',
	printCost    : ''
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'LOADING':
			return { ...state, loading: action.payload };
		case 'COLLECTION':
			return { ...state, collection: action.payload };
		case 'USER_EMAIL':
			return { ...state, userEmail: action.payload };
		case 'USER_NAME':
			return { ...state, userName: action.payload };
		case 'DESCRIPTION':
			return { ...state, description: action.payload };
		case 'MATERIALS':
			return { ...state, materials: action.payload };
		case 'ORIGINALCOST':
			return { ...state, originalCost: action.payload };
		case 'PRINTCOST':
			return { ...state, printCost: action.payload };
		case 'RESET_USER_INFO':
			return { ...state, userName: '', userEmail: '' };
		default:
			return state;
	}
};

export default authReducer;
