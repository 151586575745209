import firebase from 'firebase/app';
//import * as firebase from "firebase/app"
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

console.log('imported successfully');

var firebaseConfig = {
	apiKey            : 'AIzaSyC4h_GcqSp6yNXcXERIfEbzcysryIcsNFM',
	authDomain        : 'dlart-c0a88.firebaseapp.com',
	projectId         : 'dlart-c0a88',
	storageBucket     : 'dlart-c0a88.appspot.com',
	messagingSenderId : '456826386606',
	appId             : '1:456826386606:web:79684d15cb3b6440b4953f',
	measurementId     : 'G-MVX8B7VWW0'
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
//google sign in
export const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
	auth.signInWithPopup(provider);
};

export default firebase;
