import React from 'react';
import ArtUnit from './ArtUnit';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NavBar from '../NavBar/NavBar';
import './SpecificArt.scss';

const SpecificArtwork = () => {
	const params = useParams();
	const history = useHistory();
	const pic = useSelector((state) => state.auth.collection);
	console.log('param: ', params.id);

	let displayArt = pic.filter((n) => n.title === params.id).map((n) => <ArtUnit key={n.title} title={n.title} url={n.url} description={n.description} printCost={n.printCost} originalCost={n.originalCost} materials={n.materials} />);

	return (
		<div className='specific-art-container'>
			<NavBar leftTitle={'Home'} leftButton={() => history.push('/')} rightTitle={'Back'} rightButton={() => history.push('/collection')} />
			<div className={'specific-art-block'}>{displayArt}</div>
		</div>
	);
};
export default SpecificArtwork;
