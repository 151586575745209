import React, { useEffect, useState } from 'react';
import { getCollection } from '../../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';
import NavBar from '../NavBar/NavBar';
import './Collection.scss';
import { useHistory } from 'react-router-dom';

import PaginationBar from './PaginationBar';
import ArtThumb from './ArtThumb';

const Collection = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { collection, loading } = useSelector((state) => state.auth);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [
		filterOptions,
		setFilterOptions
	] = useState('all');

	const [
		currentPage,
		setCurrentPage
	] = useState(1);
	const [
		artworkPerPage
	] = useState(6);

	const [
		artWork,
		setArtWork
	] = useState(collection);

	const indexOfLastArtWork = currentPage * artworkPerPage;
	const indexOfFirstArtWork = indexOfLastArtWork - artworkPerPage;
	let currentArt = artWork.slice(indexOfFirstArtWork, indexOfLastArtWork);

	const handleNext = () => {
		if (currentPage < collection.length / artworkPerPage) {
			setCurrentPage(currentPage + 1);
		}
	};
	const handleBack = () => {
		if (currentPage > 0) {
			setCurrentPage(currentPage - 1);
		}
	};

	if (loading) return <div>Loading</div>;

	const refresh = () => {
		dispatch(getCollection());
	};

	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const filter = (name) => {
		setFilterOptions(name);
		let find;

		find = collection.filter((n) => n.category === name);
		setArtWork(find);
		setCurrentPage(1);
	};

	const unsetFilter = () => {
		setArtWork(collection);
		setFilterOptions('all');
	};

	let displayArt = currentArt.map((n) => (
		<ArtThumb
			key={n.title}
			url={n.url}
			// onClick={ (e) => handleClick(e, n.title) }
			onClick={() => history.push(`/collection/specific-art/${n.title}`)}
		/>
	));

	return (
		<div>
			<div className='collection-container'>
				<NavBar rightTitle={'Home'} rightButton={() => history.push('/')} leftButton={refresh} leftTitle={'Refresh'} />
				<div>
					<div className='filter-group'>
						<div className='filter-block'>
							<p style={filterOptions === 'all' ? { color: 'green' } : { color: 'white' }} onClick={unsetFilter}>
								All
							</p>
							<p style={filterOptions === 'people' ? { color: 'green' } : { color: 'white' }} onClick={() => filter('people')}>
								People{' '}
							</p>
							<p style={filterOptions === 'animals' ? { color: 'green' } : { color: 'white' }} onClick={() => filter('animals')}>
								Animals{' '}
							</p>
							<p style={filterOptions === 'meaningful' ? { color: 'green' } : { color: 'white' }} onClick={() => filter('meaningful')}>
								Meaningful
							</p>
							<p style={filterOptions === 'interpretive' ? { color: 'green' } : { color: 'white' }} onClick={() => filter('interpretive')}>
								Interpretive
							</p>
						</div>
					</div>
					<div className='display-art-block'>{displayArt}</div>
				</div>
			</div>
			<div />
			<div>
				<PaginationBar artWorkPerPage={artworkPerPage} paginate={paginate} array={artWork} />
			</div>
			<div className='collection-bottom-nav-bar'>
				<div className='goBack-button' onClick={handleBack}>
					Back
				</div>
				<div className='goBack-button' onClick={handleNext}>
					Next
				</div>
			</div>
		</div>
	);
};
export default Collection;
