import React from 'react';
import './Pagination.scss';
import { useSelector } from 'react-redux';

const PaginationBar = ({ artWorkPerPage, paginate, array }) => {
	const artCount = useSelector((state) => state.auth.collection);

	const numberOfPages = [];

    let arrayOf = array.length || artCount.length

	for (let i = 1; i <= Math.ceil(arrayOf / artWorkPerPage); i++) {
		numberOfPages.push(i);
	}

	let showNumbers = numberOfPages.map((n, idx) => (
		<div className='pagination-element' key={idx + 1} onClick={() => paginate(idx + 1)}>
			{idx}
		</div>
	));

	return (
		<div>
			<div className='select-page-text'>Select Page</div>
			<div className='pagination-block'>{showNumbers}</div>;
		</div>
	);
};
export default PaginationBar;
