import React, { Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { deleteDoc } from '../../redux/actions/authActions';
import NavBar from '../NavBar/NavBar';
import { getCollection } from '../../redux/actions/authActions';

const EditRemoveArtwork = () => {
	const dispatch = useDispatch();
	const collection = useSelector((state) => state.auth.collection);
	const message = useSelector((state) => state.auth.message);
	const loading = useSelector((state) => state.auth.loading);
	const history = useHistory();
	const handleDelete = (e, title) => {
		dispatch(deleteDoc(title));
	};
	useEffect(
		() => {
			dispatch(getCollection());
		},
		[
			message,
			dispatch
		]
	);

	const displayArtwork = collection.map((n) => (
		<div className='artwork-list' key={n.title}>
			{message !== '' && message}
			<div>{n.title}</div>
			<button onClick={(e) => handleDelete(e, n.title)}>Delete</button>
		</div>
	));

	return (
		<Fragment>
			<NavBar leftButton={() => history.push('/')} rightButton={() => history.push('/dl')} rightTitle={'Back'} leftTitle={'Home'} />
			<div className=' '>
				{loading ? (
					<div>loading</div>
				) : (
					<div className='delete-parent'>
						<div className='delete-title'>Select artwork to delete</div> {displayArtwork}{' '}
					</div>
				)}
			</div>;
		</Fragment>
	);
};
export default EditRemoveArtwork;
