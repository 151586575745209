import firebase from '../../Components/Firebase/FirebaseConfig';
import { provider, auth, db } from '../../Components/Firebase/FirebaseConfig';

export const getCollection = () => {
	return (dispatch) => {
		dispatch({ type: 'LOADING', payload: true });
		let collection = [];
		firebase
			.firestore()
			.collection('collection')
			.orderBy('timestamp', 'desc')
			.get()
			.then((snap) => {
				collection = snap.docs.map((n) => n.data());
				console.log('get Collection Action Value', collection);
			})
			.then(() => {
				dispatch({ type: 'COLLECTION', payload: collection });
				dispatch({ type: 'LOADING', payload: false });
			})
			.catch(function(error) {
				dispatch({ type: 'LOADING', payload: false });
				console.log('Error getting documents: ', error);
			});
	};
};

export const getCollectionCount = () => {
	return (dispatch) => {
		dispatch({ type: 'LOADING', payload: true });

		firebase
			.firestore()
			.collection('inventory')
			.doc('inventory')
			.get()
			.then((n) => {
				console.log('ART_COUNT: ', n.data());
				dispatch({ type: 'ART_COUNT', payload: n.data().artCount });
			})
			.then(() => dispatch({ type: 'LOADING', payload: false }))
			.catch((err) => console.log(err));
	};
};
// export const addArtWorkDocument = (password, newProfileData) => {
// 	return (dispatch) => {
// 		const { title, description, saleCost, regCost } = newProfileData;
// 		//see inner values
// 		dispatch({ type: 'LOADING', loading: true });

// 		let getProfile;
// 		if (auth.currentUser.uid === id) {
// 			const refUserAndUpdatedInfo = db.collection('users').doc(id);

// 			refUserAndUpdatedInfo.update({
// 				work     : work,
// 				location : location,
// 				bio      : bio,
// 				hobbies  : hobbies
// 			});

// 			firebase
// 				.firestore()
// 				.collection('users')
// 				.doc(id)
// 				.get()
// 				.then((doc) => {
// 					getProfile = doc.data();
// 					console.log('updatedUserProfile Action Value', getProfile);
// 					dispatch({ type: 'GET_PROFILE_DATA', getProfile });
// 					dispatch({ type: 'LOADING', loading: false });
// 				})
// 				.catch(function(error) {
// 					console.log('Error getting documents: ', error);
// 				});
// 		} else {
// 			return;
// 		}
// 	};
// };

export const handleGoogleSignInAndCreateUserDoc = () => {
	return async (dispatch) => {
		dispatch({ type: 'LOADING', payload: true });
		dispatch({ type: 'SIGN_IN_MESSAGE', payload: 'Sign In SuccessFull' });

		auth.signInWithPopup(provider).then(() => {
			db.collection('users').doc(auth.currentUser.email).get().then((data) => {
				if (data.exists) {
					dispatch({ type: 'MESSAGE', payload: 'doc exists, setting dispatch to get data' });

					dispatch(getBasicUserInfo(auth.currentUser.email));
					return;
				}
				if (!data.exists) {
					console.log('created user aut info given ', auth.currentUser);
					dispatch(createBasicUserInfo(auth.currentUser));
					dispatch({ type: 'MESSAGE', payload: 'doc does NOT EXIST, setting dispatch to get data' });
					return;
				}
			});
		});
		dispatch({ type: 'LOADING', payload: false });
	};
};

export const getBasicUserInfo = (userInfo) => {
	return async (dispatch) => {
		let basicUserInfo;

		db
			.collection('users')
			.doc(`${userInfo}`)
			.get()
			.then((snap) => {
				basicUserInfo = snap.data();

				dispatch({ type: 'USER_NAME', payload: basicUserInfo.name });
				dispatch({ type: 'USER_EMAIL', payload: basicUserInfo.email });
				return;
			})
			.then(() => {
				dispatch({ type: 'LOADING', payload: false });
			})
			.catch((err) => {
				console.log(err);
			});
		//get the users profile
	};
};
export const deleteDoc = (title) => {
	return async (dispatch) => {
		dispatch({ type: 'LOADING', payload: true });
		db
			.collection('collection')
			.doc(`${title}`)
			.delete()
			.then(() => {
				dispatch(getCollection());
				dispatch({ type: 'LOADING', payload: false });
				dispatch({ message: 'Successfully Removed Artwork From Db' });
				setTimeout(() => {
					dispatch({ message: '' });
				}, 2000);
			})
			.catch((err) => {
				console.log(err);
			});
		//get the users profile
	};
};

export const createBasicUserInfo = (userInfo) => {
	return async (dispatch) => {
		let basicUserInfo;

		const newUser = {
			email : userInfo.email,
			name  : userInfo.displayName
		};

		db
			.collection('users')
			.doc(`${userInfo.email}`)
			.set(newUser)
			.then((snap) => {
				basicUserInfo = snap.data();
				dispatch({ type: 'BASIC_USER_INFO', payload: basicUserInfo });
				dispatch({ type: 'LOADING', payload: false });
			})
			.catch((err) => {
				console.log(err);
			});
		//get the users profile
	};
};

export const addArtworkToSite = ({ url, title, description, originalCost, printCost }) => {
	return (dispatch) => {
		console.log('from action', title, description, originalCost, printCost);
		let timestamp = firebase.firestore.FieldValue.serverTimestamp();
		db
			.collection('collection')
			.doc(`${title}`)
			.set({
				url,
				title,
				description,
				originalCost,
				printCost,
				timestamp
			})
			.then(() => {
				dispatch({ type: 'ADDED_ARTWORK_SUCCESS', payload: 'Event Successfully Created!' }).catch((err) => console.log(err));
			})
			.catch((err) => {
				console.log((err) => console.log(err));
				dispatch({ type: 'ERROR', payload: `Sorry you do not have adequate permissions to add data to this database.. ${err}` });
			});
	};
};
export const updateArtworkOnThisSite = ({ title, description, originalCost, printCost }) => {
	return (dispatch) => {
		console.log('from action', title, description, originalCost, printCost);

		db
			.collection('collection')
			.doc(`${title}`)
			.update({
				title,
				description,
				originalCost,
				printCost
			})
			.then(() => {
				dispatch({ type: 'ADDED_ARTWORK_SUCCESS', payload: 'Event Successfully Created!' }).catch((err) => console.log(err));
			})
			.catch((err) => {
				console.log((err) => console.log(err));
				dispatch({ type: 'ERROR', payload: `Sorry you do not have adequate permissions to add data to this database.. ${err}` });
			});
	};
};
export const removeArtworkFromSite = ({ title }) => {
	return (dispatch) => {
		console.log('from action', title);

		db
			.collection('collection')
			.doc(`${title}`)
			.delete()
			.then(() => {
				dispatch({ type: 'ADDED_ARTWORK_SUCCESS', payload: 'Event Successful!' }).catch((err) => console.log(err));
			})
			.catch((err) => {
				console.log((err) => console.log(err));
				dispatch({ type: 'ERROR', payload: `Sorry you do not have adequate permissions to remove data from this database.. ${err}` });
			});
	};
};
