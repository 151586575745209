import React, { Fragment, useEffect } from 'react';
import '../Components/Home.scss';
import OptionBox from './OptionBox/OptionBox';
import { useHistory } from 'react-router-dom';
import { getCollection } from '../redux/actions/authActions';
import { useDispatch, useSelector } from 'react-redux';

const Home = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const { collection } = useSelector((state) => state.auth);

	useEffect(
		() => {
			collection[1] === undefined && dispatch(getCollection());
			window.scrollTo(0, 0);
		},
		[
			collection,
			dispatch
		]
	);

	const handleEmail = () => {
		window.open('mailto:dlwell292@gmail.com?subject=Interested_via_website&cc=williamwellman98@gmail.com');
	};
	const handleButtonTwo = () => {
		history.push('/collection');
	};
	const handleButtonThree = () => {
		history.push('/dl');
	};

	return (
		<Fragment>
			<div className='parent'>
				<OptionBox buttonEmailClick={handleEmail} buttonTwoOnClick={handleButtonTwo} buttonThreeOnClick={handleButtonThree} />
			</div>
		</Fragment>
	);
};
export default Home;
