import React, { useEffect } from 'react';
import { auth } from '../Firebase/FirebaseConfig';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { handleGoogleSignInAndCreateUserDoc, getBasicUserInfo } from '../../redux/actions/authActions';
import './Admin.scss';
import NavBar from '../NavBar/NavBar';

const GoogleSignIn = () => {
	const dispatch = useDispatch();
	const email = useSelector((state) => state.auth.userEmail);
	const history = useHistory();

	useEffect(() => {
		dispatch(getBasicUserInfo());
	}, []);

	const googleSignIn = () => {
		dispatch(handleGoogleSignInAndCreateUserDoc());
	};

	const signOut = () => {
		auth.signOut().then(() => {
			console.log('you logged off');
			dispatch({ type: 'RESET_USER_INFO' });
		});
	};

	return (
		<div className='sign-in-parent'>
			<NavBar rightButton={() => history.push('/')} rightTitle={'Back'} leftButton={() => history.push('/')} leftTitle={'Home'} />
			{email === '' && (
				<div className='after-sign-in-block'>
					<h1>Please Sign In</h1>
					<button onClick={googleSignIn}>Sign In</button>
				</div>
			)}

			<div>
				{email === 'dlwell292@gmail.com' || email === 'williamwellman98@gmail.com' ? (
					<div className='after-sign-in-block'>
						<div> What do you want to do?</div>
						<div>
							<button onClick={() => history.push('/new-artwork')}>Add Art Work</button>
							<button onClick={() => history.push('/edit-artwork')}>Delete Art Work</button>
						</div>
						<div>
							<button onClick={signOut}>Sign Out</button>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};
export default GoogleSignIn;
