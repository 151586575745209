import React from 'react';
import './ArtThumb.scss';

const ArtThumb = ({ title, url, onClick }) => {
	return (
		<div className='image-group-thumb' onClick={onClick}>
			<img src={`${url}`} alt={`${title}`} className='collection-image-width' />
		</div>
	);
};
export default ArtThumb;
